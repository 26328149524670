import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Button,
  Segment,
  Container,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";

const IndexPage = (props: LayoutProps) =>
  <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <Container text>
        <Header inverted as="h1">Unstacked C</Header>
        <Header inverted as="h2">Stackless Multithreading for C</Header>
        <Button primary size="huge" href={"/technology"}>Technology</Button><Button primary size="huge" href={"/download"}>Download</Button>
      </Container>
    </Segment>

    {/* About this starter */}
    <Segment vertical className="stripe">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="8">
            <Header>Overview</Header>
            <p>
              UnStacked C is a stackless multithreading system. It allows programmers to write software in a traditional preemptive multithreaded fashion, but allows the programs to execute in an event driven fashion.
                </p>
            <Header></Header>
            <p>
              It was initially designed for TinyOS, but it can be run on PCs and in embedded systems.
                </p>
          </Grid.Column>
          <Grid.Column width="6" floated="right">
            {/* TODO replace with a pretty GIF */}
            <Header>Project Status</Header>
            <p>
              This project has not been updated much recently. This is based off of graduate work done in 2006-2011.
            </p>
            <p>That said, I'm happy to help out any ongoing usages or developments. Don't hesitate to reach out.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    {/* Key features */}
    <Segment vertical className="stripe alternate feature">
      <Grid columns="3" textAlign="center" divided relaxed stackable className="container">
        <Grid.Row>
          <Grid.Column>
            <Header icon>
              <Icon name="wizard"></Icon>
              Translate Threads to Event Driven
            </Header>
            <p>
              Unstacked C is a C to C compiler which transforms multithreaded code into event driven code with a single stack.
            </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="tasks"></Icon>
              Millions of Threads
            </Header>
            <p>
              With Unstacked C it is possible to run one million threads in less than 50MB of RAM in a simple application.
                </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="resize vertical"></Icon>
              Reduce Embedded RAM Footprint
            </Header>
            <p>
              When recompiling embedded applications with Unstacked C, we have reduced total memory consumption by more than 25%.
                </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>;

export default withLayout(IndexPage);
